<template>
	<v-container>
		<v-carousel cycle	height="200" hide-delimiters hide-delimiter-background show-arrows-on-hover>
			<v-carousel-item v-for="(slide, i) in slides" :key="i" :src="'/pictures/picture/slider/' + slide.link">
				<v-sheet class="justBlack">
					<div>{{ slide.desc_short.toUpperCase() }}</div>
				</v-sheet>
				<v-sheet class="justWhite">
					<div>{{ slide.desc_long }}</div>
				</v-sheet>
			</v-carousel-item>
		</v-carousel>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	name: 'Slides',
	data() {
		return {
			slides: [
				{
					desc_short: this.$i18n.t('slides.loading'),
					desc_long: this.$i18n.t('slides.loading'),
					url: this.$i18n.t('slides.loading'),
				},
			],
		}
	},
	mounted() {
		ApiService.get(`/slides`).then((response) => {
			this.slides = response.data;
		}).catch(() => {});
	},
};
</script>

<style scoped>
.justBlack {
	color: white;
	background-color: black;
	text-align: center;
	font-weight: bold;
}
.justWhite {
	color: black;
	background: rgba(255, 255, 255, 0.8);
	text-align: center;
}
</style>

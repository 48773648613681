<template>
	<v-container fluid>
		<v-container>
			<v-row>
				<v-col>
					<EnewsLast></EnewsLast>
				</v-col>
			</v-row>
			<v-row>
				<v-col :lg="cols[0]" :md="cols[0]" >
					<ProductMenu></ProductMenu>
				</v-col>
				<v-col :lg="cols[1]" :md="cols[1]">
					<Slides></Slides>
				</v-col>
				
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import Slides from "@/components/Slides.vue"
import ProductMenu from "@/components/ProductMenu.vue"
import EnewsLast from '@/components/EnewsLast.vue'

export default {
	components: {
		Slides,
		ProductMenu,
		EnewsLast
	},
	data: () => ({
		
	}),
 
	computed: {
		cols() {
			const { lg, md } = this.$vuetify.breakpoint;
			return lg ? [6, 6] : md ? [6, 6] : [];
		},
	},
};
</script>

<style scoped>
</style>

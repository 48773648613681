<template>
	<v-container>
		<v-card>
			<v-card-title>{{ $t('product.title_main') }}</v-card-title>
			<v-card-text>
		<!-- <h2>Produkty</h2> -->
				<v-treeview v-model="tree" :open="initially_open" :items="items" activatable item-key="id" open-on-click>
					<template slot="label" slot-scope="props">
						<router-link :to="create_route(props.item.id)" v-if="!props.item.children">{{ props.item.label }}</router-link>
						<span v-else>{{ props.item.label }}</span>
					</template>
				</v-treeview>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	name: 'ProductMenu',
	data: () => ({
		tree: [],
		initially_open: [],
		items: [],
	}),
	methods: {
		create_route(id) {
			return "/productCategory/" + id;
		},
	},
	mounted() {
		ApiService.get(`/cat`).then((response) => {
			this.items = response.data;
		}).catch(() => {});
	},
};
</script>

<style scoped>
.v-treeview-node__root {
	color: red;
	min-height: 13px;
}
a:link, a:visited, a:hover, a:active {
	color: black;
	text-decoration: none;
}
</style>
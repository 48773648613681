<template>
	<v-container>
        <v-card>
            <v-card-title>{{ $t("enews.title") }}</v-card-title>
            <v-card-text class="narrow">
                <v-list dense>
                    <v-list-item v-for="enew in enews" :key="enew.id" route to="/enews">
                        <v-list-item-icon>
                            <v-icon>mdi-new-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ enew.nadpis }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>   
        </v-card>             
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	name: 'EnewsLast',
	data: () => ({
		enews: []
	}),
	methods: {
	},
	mounted() {
		ApiService.get(`/enews/last/1`).then((response) => {
			this.enews = response.data;
		}).catch(() => {});
	},
};
</script>

<style scoped>
.v-list-item__title {
    white-space: normal;
}
.item__content {
    text-align: justify;
}
.narrow {
    padding: 0;
}
</style>